import React from "react"
import { Layout } from "components/Layout"

const NotFoundPage: React.FC = () => (
    <Layout>
        <div className="inner">
            <h1>404: Not Found</h1>
            <p>The page you are looking for doesn't exist...</p>
        </div>
    </Layout>
)

export default NotFoundPage
